.register{
  padding: 20px;
  line-height: 50px;
  font-size: 16px;
}
.register p{
  line-height: 30px;
  font-size: 16px;
}
.title{
  /* margin: 0 auto; */
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
a{
  word-break: break-all;
}

/* .protocol-paragraph{
  line-height: 30px;
  font-size: 16px;
}
.protocol-section{
  line-height: 30px;
  font-size: 16px;
} */