html,body{
  background-color: #fff;
}
.center-box{
	width: 1200px;
	margin: 0 auto;
}
.header-box.nav-box{
  background-color:#fff!important;
}
.nav-box .search-box>span{
  color: #333;
}
.nav-box .search-box #search-text{
  color: #666;
  font-size: 10px;
}

.banner-bg{
  height: 650px;
  width: 100%;
}
.nav-box .search-box{
  float: right;
  margin-right: 50px;
  height: 30px;
  border: 1px solid #eee;
  line-height: 30px;
  margin-top: 15px;
  font-size: 10px;
}
.nav-box .search-box>span{
  border-right: 1px solid #eee;
}
.banner-width {
  position: relative;
  color: #fff;
  width: 100%;
}
.ver-middle-add.v-b{
  position: absolute;
  margin-left: 50%;
  left: -600px;
  bottom: 0;
}
.er-code-box .width-list.btn-list{
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  color: #666;
  font-size: 14px;
  line-height: 23px;
  text-align: right;
}
.width-list{
  width: 214px;
  background-color: #1786d2;
  padding: 10px;
  margin: 0 auto;
  border-radius: 3px;
  
}
.er-img{
  width: 100%;
  height: 194px;
}
.er-code-box .btn-list img{
  width: 20px;
  float: left;
  margin-right: 5px;
}
.er-code-box{
  margin: 90px 0 25px 0;
}
.center-box.re{
  position: relative;
  /* height: 500px; */
  /* margin-left: -600px; */
  left: 50%;
  top: 50%;
}
.ver-middle {
  width: 1200px;
}
.text-b{
  font-weight: bold;
  color: #0085d0;
  font-size: 38px;
  margin: 60px 0 20px 0;
}
.english-grey{
  color:#999999;
  font-size: 16px;
  margin-bottom: 50px;
}
.mess-de{
  color:#444444;
  font-size: 18px;
  line-height: 30px;
}
.bg-blue{
  width: 100%;
  background-color: #ebf5ff;
}
.bg-blue .mess-de{
  text-align: right;
}
.bg-blue .english-grey{
  text-align: right;
}
.tab-box{
  width: 100%;
  margin: 20px 0;
}
.tab-box .tab-item{
  width: 19.2%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: block;
  float: left;
  margin-right: 1%;
}
.tab-box .tab-item.c1{
  background-color: #c3ecff;
 
}
.tab-box .tab-item.c2{
  background-color: #95be3e;
 
}
.tab-box .tab-item.c3{
  background-color: #f8ce44;
}
.tab-box .tab-item.c4{
  background-color: #23c8c0;
}
.tab-box .tab-item.c5{
  background-color: #c3ecff;
  margin-right: 0;
}
.center-box.tab-img{
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-img-center{
  /* margin: 0 calc((100% - 90%) / 2); */
}

.center-box.tab-img div{
  position: relative;
  height: auto;
  width: 30%;
}
.center-box.tab-img div img{
  border: 1px solid #eee;
  border-radius: 0 0 12px 12px;
}
.center-box.tab-img div img.center-fl{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(0,0%);
  -webkit-transform: translate(0,0%);
  -o-transform: translate(0,0%);
  -moz-transform: translate(0,0%);
  transform: translate(0,0%);
  margin-top: -366px;
  margin-left: -178px;
}
.last-fl{
  right: 0;
}
.fix-box-list{
  padding: 10px 3px;
  position: relative;
}


.nav-box .search-box .serch-ico {
  background-position: -51px -11px;
}
.nav-box .search-box .course-down-ico{
  background-position: -177px -14px;
}
.nav-box .search-box .course-up-ico {
  background-position: -189px -14px;
}

.ercode-big .ercode-text{
  margin-top: 5px;
  padding: 0 5px;
}
.fix-box-er:hover .ercode-big{
  display: block;
}
.phone-box .ercode-big{
  left: -133px;
  bottom:0px;
  padding: 8px 10px;
}
.floating_window .fix-box-list.phone-box{
display: block;
}
.center-box.re .ver-middle{
  margin-left: -600px;
  -ms-transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
  -o-transform: translate(0%, 0%);
  -moz-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  /* margin-top: -170px; */
}


.fl {
	float: left;
}

.rt {
	float: right;
}

.tc {
	text-align: center;
}
.tcr{
	text-align: right;
}

.home-img{
  width: auto;
  height: 300px;
  margin-left: 100px;
}
.head-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 330px;
}